<script setup>
import {computed} from "vue"
import AleaView from "@aleahealth/common/apps/components/alea/AleaView.vue"
import {
  House,
  Sparkles,
  Stethoscope,
  ContactRound,
  MessagesSquare,
  CalendarClock,
  CircleUserRound,
  Handshake,
} from "lucide-vue-next"
import {useUserStore} from "@aleahealth/common/apps/stores/user.js"
import {storeToRefs} from "pinia"

const userStore = useUserStore()
const {user, userIsClient, userIsProvider, userIsAdmin} = storeToRefs(userStore)

const showDashboard = true
const showAIChat = computed(() => userIsClient.value && user.value.currentTherapy)
const showProvider = computed(() => userIsClient.value && user.value.currentTherapy)
const showChat = computed(() => userIsProvider.value)
const showSchedule = computed(() => userIsClient.value || userIsProvider.value)
const showClients = computed(() => userIsProvider.value)
const showSessions = computed(() => userIsClient.value)
const showProviders = computed(() => userIsClient.value && !user.value.currentTherapy)
const showSignInAs = computed(() => userIsAdmin.value)
const showProfile = true

// menu items
const menuItems = computed(() => [
  // dashboard
  ...(showDashboard
    ? [
        {
          title: "Home",
          icon: House,
          route: {name: "DashboardView"},
        },
      ]
    : []),

  // AI chat
  ...(showAIChat.value
    ? [
        {
          title: "AI chat",
          icon: Sparkles,
          route: {name: "MessagingAIView"},
        },
      ]
    : []),

  // provider (chat client <-> provider)
  ...(showProvider.value
    ? [
        {
          title: "Provider",
          icon: MessagesSquare,
          route: {name: "MessagingProviderView"},
        },
      ]
    : []),

  // chat (provider <-> clients)
  ...(showChat.value
    ? [
        {
          title: "Chat",
          icon: MessagesSquare,
          route: {name: "MessagingProviderView"},
        },
      ]
    : []),

  // schedule
  ...(showSchedule.value
    ? [
        {
          title: "Schedule",
          icon: CalendarClock,
          route: {name: "ScheduleView"},
        },
      ]
    : []),

  // clients
  ...(showClients.value
    ? [
        {
          title: "Clients",
          icon: ContactRound,
          route: {name: "ClientsView"},
        },
      ]
    : []),

  // sessions
  ...(showSessions.value
    ? [
        {
          title: "Sessions",
          icon: Handshake,
          route: {name: "SessionsView"},
        },
      ]
    : []),

  // providers
  ...(showProviders.value
    ? [
        {
          title: "Therapists",
          icon: Stethoscope,
          route: {name: "ProvidersView"},
        },
      ]
    : []),

  // sign in as
  ...(userIsAdmin.value
    ? [
        {
          title: "Sign in as",
          icon: ContactRound,
          route: {name: "SignInAsView"},
        },
      ]
    : []),

  // profile
  ...(showProfile
    ? [
        {
          title: "Profile",
          icon: CircleUserRound,
          route: {name: "ProfileView"},
        },
      ]
    : []),
])
</script>

<template><AleaView :menuItems /></template>
